<template>
    <div class="account-index-page" v-if="userInfo">
        <el-card class="basic-card">
            <div slot="header" class="clearfix">基础信息</div>
            <div class="basic-info-form">
                <div class="form-item">
                    <div class="label">头像</div>
                    <div class="filed margin-l-20">
                        <!-- <img :src="userInfo.avatar" class="avatar" alt="avatar"> -->
                        <el-avatar :size="64" :src="userInfo.avatar" icon="el-icon-user" class="fonts-36"></el-avatar>
                    </div>
                </div>
                <div class="form-item">
                    <div class="label">昵称</div>
                    <div class="filed margin-l-20">
                        <span v-if="userInfo.nickname">{{userInfo.nickname}}</span>
                        <span class="color-666666" v-else>无昵称</span>
                        <span class="margin-l-15 cursor-pointer" @click="editNickNameEvent">
                            <svg-icon name="ib-edit-o"></svg-icon>
                        </span>
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="basic-card margin-t-20">
            <div slot="header" class="clearfix">账户管理</div>
            <div>
                <div class="flex flex-align-center border-default radius-6 bg-F8F8F8" style="padding: 25px 30px;">
                    <div class="color-success fonts-20 margin-r-25" v-if="userInfo.phone">
                        <svg-icon name="ib-check-o"></svg-icon>
                    </div>
                    <div class="color-error fonts-24 margin-r-25" v-else>
                        <svg-icon name="ib-warning-o"></svg-icon>
                    </div>
                    <div class="flex-1">
                        <div class="">手机号码</div>
                        <div class="color-7C7C7C margin-t-10">{{userInfo.phone || '未绑定，绑定后获得更好体验'}}</div>
                    </div>
                    <div class="cursor-pointer fonts-15" @click="changeBindEvent('phone')">{{userInfo.phone?'更改':'绑定'}}</div>
                </div>
                <div class="flex flex-align-center border-default radius-6 bg-F8F8F8 margin-t-15" style="padding: 25px 30px;">
                    <div class="color-success fonts-20 margin-r-25" v-if="userInfo.openId">
                        <svg-icon name="ib-check-o"></svg-icon>
                    </div>
                    <div class="color-error fonts-24 margin-r-25" v-else>
                        <svg-icon name="ib-warning-o"></svg-icon>
                    </div>
                    <div class="flex-1">
                        <div class="">微信</div>
                        <div class="color-7C7C7C margin-t-10">{{userInfo.openId?'已绑定，可通过微信扫码登录':'未绑定，绑定后获得更好体验'}}</div>
                    </div>
                    <template v-if="userInfo.openId">
                        <div class="cursor-pointer fonts-15 color-error" @click="changeBindEvent('wechat')">解绑</div>
                    </template>
                    <template v-else>
                        <div class="cursor-pointer fonts-15" @click="changeBindEvent('wechat')">绑定</div>
                    </template>
                </div>
                
                <div class="flex flex-align-center border-default radius-6 bg-F8F8F8 margin-t-15" style="padding: 25px 30px;">
                    <div class="color-success fonts-20 margin-r-25" v-if="userInfo.email">
                        <svg-icon name="ib-check-o"></svg-icon>
                    </div>
                    <div class="color-error fonts-24 margin-r-25" v-else>
                        <svg-icon name="ib-warning-o"></svg-icon>
                    </div>
                    <div class="flex-1">
                        <div class="">邮箱</div>
                        <div class="color-7C7C7C margin-t-10">{{userInfo.email || '未绑定，绑定后获得更好体验'}}</div>
                    </div>
                    <div class="cursor-pointer fonts-15" @click="changeBindEvent('email')">{{userInfo.email?'更改':'绑定'}}</div>
                </div>
                <div class="flex flex-align-center border-default radius-6 bg-F8F8F8 margin-t-15" style="padding: 25px 30px;">
                    <div class="color-success fonts-20 margin-r-25">
                        <svg-icon name="ib-check-o"></svg-icon>
                    </div>
                    <div class="flex-1">
                        <div class="">账户密码</div>
                        <div class="color-7C7C7C margin-t-10">已设置，可通过账号密码登录</div>
                    </div>
                    <div class="cursor-pointer fonts-15" @click="changeBindEvent('password')">更改</div>
                </div>
                
            </div>
        </el-card>
        <alert-dialog ref="alertDialogDom" 
            message="确定要解除绑定吗？" 
            type="confirm" 
            buttonName="确定"
            buttonType="danger"
            @confirmEvent="unbindWechatConfirmEvent"></alert-dialog>
        <change-phone ref="changePhoneDialog"></change-phone>
        <change-email ref="changeEmailDialog"></change-email>
        <change-password ref="changePasswordDialog"></change-password>
        <change-nickname ref="ChangeNicknameDialog"></change-nickname>
    </div>
</template>
<script>
import { mapState } from "vuex";
import config from '@/utils/config';
import { userApi } from '@/utils/api';
import AlertDialog from '@/components/AlertDialog';
import PageTitle from '@/components/PageTitle';
import ChangePassword from './components/ChangePassword';
import ChangePhone from './components/ChangePhone';
import ChangeEmail from './components/ChangeEmail';
import ChangeNickname from './components/ChangeNickname';
export default {
    components: { AlertDialog, PageTitle, ChangePassword, ChangePhone, ChangeEmail, ChangeNickname, },
    data(){
        return{
            nickname: '',
            loading: false,
            alert: {
                
            }
        }
    },
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
		}),
	},
    methods:{
        beforeAvatarUpload(file){

        },
        handleAvatarSuccess(response, file, fileList){
            let data = response.data;
            this.imageUrl = data.url;
            //TODO 更新前端存储用户信息 

        },
        handleAvatarError(err, file, fileList){
            let res = JSON.parse(err.message);
            if(err.status === 401){
                this.$message.error('登录状态已过期，请重新登录');
                this.$cookies.remove('token');
                localStorage.setItem('redirect',location.href);
                setTimeout(()=>{
                    location.replace(`/login`);
                },1000);
            }else{
                this.$message.error(res.message||(file.name+'上传失败，请重试'));
            }
        },
        editNickNameEvent(){
             this.$refs['ChangeNicknameDialog'].setShow(true);
        },
        changeBindEvent(key){
            switch (key){
                case 'phone':
                    this.$refs['changePhoneDialog'].setShow(true);
                    break;
                case 'email':
                    this.$refs['changeEmailDialog'].setShow(true);
                    break;
                case 'password':
                    this.$refs['changePasswordDialog'].setShow(true);
                    break;
                case 'wechat':
                    if(this.userInfo.openId){
                        this.$refs.alertDialogDom.setShow(true);
                    }else{
                        if(this.userInfo.phone){
                            this.$store.dispatch('user/setBindWechatVisible', true);
                        }else{
                            this.$message.error('请先绑定手机号');
                        }
                    }
                    
                    break;
            }
        },
        unbindWechatConfirmEvent(){
            let url = userApi.unbindWechat;
            this.$ajax.get(url).then(res => {
                if (res.status === 200){
                    this.$router.go(0);
                    this.$message.success('解绑成功');

                }
            })
        },
    },
    mounted() {
        this.nickname = this.userInfo.nickname;
    }
}
</script>
<style lang="scss" scoped>
.account-index-page{
    .avatar{
        border-radius: 50%;
        width: 64px;
        height: 64px;
    }
    .basic-card{
        box-shadow: initial;
        ::v-deep .el-form-item__label{
            line-height: 1;
        }
    }
    .basic-info-form{
        .form-item{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .label{
                font-size: 15px;
                line-height: 24px;
                padding-right: 20px;
            }
            .field{
                font-size: 15px;
                line-height: 24px;
            }
        }
    }
}
</style>
