<template>
    <el-dialog title="绑定/更改手机号" :visible.sync="show" destroy-on-close @close="closeDialogEvent" width="440px" top="20px">  
        <div class="change-phone-form-content">
            <el-form ref="changePhoneForm" :rules="formRules" :model="form">
                <el-form-item prop="phone">
                    <el-input v-model="form.phone" placeholder="输入新手机号" autocomplete="off">
                        <el-select v-model="form.countryCode" filterable slot="prepend" placeholder="请选择" style="width: 85px;">
                            <template v-for="(item, index) in countryCodeList">
                                <el-option :label="`+${item.code}`" :value="item.code" :key="index">{{ `(+${item.code}) ${item.name}` }}</el-option>
                            </template>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item prop="captcha">
                    <div class="flex">
                        <div class="flex-1">
                            <el-input v-model="form.captcha" placeholder="输入验证码" autocomplete="off"></el-input>
                        </div>
                        <div class="margin-l-15">
                            <el-button plain @click="getCaptcha" :disabled="hasSend">{{hasSend?`${reSendTime}后可发送`:'发送验证码'}}</el-button>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <div class="actions text-center" style="margin-top: 50px;">
                <el-button round plain size="small" @click="show = false">取消</el-button>
                <el-button round type="primary" size="small" style="margin-left:20px;" :loading="loading" @click="submitChangePhone">确认修改</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { userApi } from '@/utils/api';
import tools from '@/utils/tools';
export default {
    name: 'ChangePhone',
    data(){
        const validatePhone = (rule, value, callback) => {
            if (value === ''){
                callback(new Error('请输入新手机号'));
            }/* else if (!tools.checkPhone(value)){
                callback(new Error('新手机号格式不正确'));
            } */else{
                callback();
            }
        }
        return {
            reSendTime: 60,
            hasSend: false,
            sendTimer: null,
            show: false,
            loading: false,
            form: {
                phone: '',
                captcha: '',
                countryCode: '86',
            },
            formRules: {
                phone: [{ validator: validatePhone, trigger: 'blur' }],
                captcha: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }],
            },
            countryCodeList: [],
        }
    },
    computed: {
        ...mapState({
			userInfo: state => state.user.userData,
		}),
    },
    created() {
        this.countryCodeList = this.$i18n.locale === 'zh-CN' ? this.$t('common.country_code_list') : this.$t('common.country_code_list').sort((a, b) => {
            if(a.name > b.name) {
                return 1;
            }else if(a.name < b.name) {
                return -1;
            }
            return 0;
        });
    },
    methods: {
        setShow(val){
            this.show = val;
        },
        closeDialogEvent(){
            this.form = {
                phone: '',
                captcha: '',
            };
            clearInterval(this.sendTimer);
            this.sendTimer = null;
            this.reSendTime = 60;
            this.hasSend = false;
        },
        getCaptcha(){
            let self = this;
            if(!this.form.phone){
                this.$message.error('请填写手机号');
                return;
            }
            /* if(!tools.checkPhone(this.form.phone)){
                this.$message.error('手机号格式不正确');
                return;
            } */
            this.$ajax.post(userApi.sendCaptchaAuth, {
                account: this.form.phone, 
                authType: 'PHONE', 
                regionCode: '+' + this.form.countryCode
            }).then(res=> {
                if(res.status === 200){
                    this.$message.success('验证码已发送，请注意查收');
                    this.hasSend = true;
                    this.reSendTime = this.reSendTime - 1;
                    this.sendTimer = setInterval(()=> {
                        self.reSendTime = self.reSendTime - 1;
                        if(self.reSendTime < 0){
                            clearInterval(self.sendTimer);
                            self.reSendTime = 60;
                            self.hasSend = false;
                        }
                    }, 1000);
                }
            })
        },
        submitChangePhone(){
             this.$refs['changePhoneForm'].validate((valid) => {
                if (valid) {
                    //成功
                    // console.log('success');
                    let url = this.$ajax.format(userApi.settingAccount,{id: this.userInfo.userId});
                    let postData={
                        regionCode: '+' + this.form.countryCode,
                        account: this.form.phone,
                        captcha: this.form.captcha
                    };
                    this.loading = true;
                    this.$ajax.post(url,postData).then(res=>{
                        if(res.status === 200){
                            this.$message.success('绑定/更改手机号成功');
                            this.show = false;
                            this.$store.dispatch('user/queryUserInfoAction');
                        }
                    }).finally(()=>{
                        this.loading = false;
                    });
                    
                } else {
                    return false;
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.change-phone-form-content{
    padding: 0 60px;
    ::v-deep .el-input-group__prepend {
        background-color: #fff;
    }
}
</style>
