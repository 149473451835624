<template>
    <el-dialog title="修改昵称" :visible.sync="show" destroy-on-close @close="closeDialogEvent" width="360px" top="20px">  
        <div class="change-nickname-form-content">
            <el-form ref="changeNicknameForm" :rules="formRules" :model="form">
                <el-form-item prop="nickname">
                    <el-input v-model="form.nickname" placeholder="输入昵称" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div class="actions text-center" style="margin-top: 50px;">
                <el-button round type="primary" size="small" style="margin-left:20px;" :loading="loading" @click="submitChangeNickname">确认修改</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { userApi } from '@/utils/api';
export default {
    name: "ChangeNickname",
    data() {
        return {
            show: false,
            loading: false,
            form: {
                nickname: '',
            },
            formRules: {
                nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
            }
        }   
    },
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
		}),
	},
    methods: {
        setShow(val){
            this.show = val;
            this.form.nickname = this.userInfo.nickname;
        },
        closeDialogEvent(){
            this.form = {
                nickname: '',
            };
            this.loading = false;
        },
        submitChangeNickname(){
            this.$refs['changeNicknameForm'].validate((valid) => {
                if (valid) {
                    let url = userApi.queryUserInfo;
                    this.loading = true;
                    this.$ajax.post(url, this.form).then(res=> {
                        if(res.status === 200){
                            this.$message.success('昵称修改成功');
                            this.$store.dispatch('user/queryUserInfoAction');
                            this.show = false;
                        }
                    }).finally(()=> {
                        this.loading = false;
                    });
                }
            });
        },
    },
    mounted() {
        this.form.nickname = this.userInfo.nickname;
    }

}
</script>
<style lang="scss" scoped>
.change-nickname-form-content{
    padding: 0 40px;
}
</style>
