<template>
    <el-dialog title="更改密码" :visible.sync="show" destroy-on-close @close="closeDialogEvent" width="440px" top="20px">  
        <div class="change-password-form-content">
            <el-form ref="changePasswordForm" :rules="formRules" :model="form">
                <el-form-item prop="old">
                    <el-input type="password" v-model="form.old" placeholder="输入旧密码" autocomplete="off" show-password></el-input>
                </el-form-item>
                <el-form-item prop="new">
                    <el-input type="password" v-model="form.new" placeholder="输入新密码" autocomplete="off" show-password></el-input>
                </el-form-item>
                <el-form-item prop="confirm">
                    <el-input type="password" v-model="form.confirm" placeholder="再次输入新密码" autocomplete="off" show-password></el-input>
                </el-form-item>
            </el-form>
            <div class="actions text-center" style="margin-top: 50px;">
                <el-button round plain size="small" @click="show = false">取消</el-button>
                <el-button round type="primary" size="small" style="margin-left:20px;" :loading="loading" @click="submitChangePassword">确认修改</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { Base64 } from "js-base64";
import { mapState } from 'vuex';
import { userApi } from '@/utils/api';
export default {
    name: 'ChangePassword',
    data(){
        const validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入新密码'));
            } else{ 
                let rst = /^(?=.*[a-z])(?=.*\d).{6,20}$/.test(value); // ^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]{6,20})$
                if (rst) {
                    callback();
                }else{
                    callback(new Error('新密码必须是6-20位大小写英文字母和数字'));
                }
            }
        };
        const validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入新密码'));
            }else if(value !== this.form.new){
                callback(new Error('两次输入密码不一致'));
            }else{
                callback();
            }
        };
        return {
            show: false,
            loading: false,
            form: {
                old: '',
                new: '',
                confirm: '',
            },
            formRules: {
                old: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
                new: [{ validator:validatePass, trigger: 'blur' }],
                confirm: [{ validator:validatePass2, trigger: 'blur' }]
            }
        }
    },
    computed: {
        ...mapState({
			userInfo: state => state.user.userData,
		}),
    },
    methods:{
        setShow(val){
            this.show = val;
        },
        closeDialogEvent(){
            this.form = {
                old: '',
                new: '',
                confirm: '',
            };
            this.loading = false;
            
        },
        submitChangePassword(){
            this.$refs['changePasswordForm'].validate((valid) => {
                if (valid) {
                    //成功
                    // console.log('success');
                    let url = userApi.setPassword;
                    let postData={
                        newPassword: Base64.encode(this.form.new),
                        oldPassword: Base64.encode(this.form.old)
                    };
                    this.loading = true;
                    this.$ajax.post(url,postData).then(res=>{
                        if(res.status === 200){
                            this.$message.success('密码已修改');
                            this.show = false;
                        }
                    }).finally(()=>{
                        this.loading = false;
                    });
                    
                } else {
                    return false;
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.change-password-form-content{
    padding: 0 60px;
}
</style>
