<template>
    <el-dialog title="绑定/更改邮箱" :visible.sync="show" destroy-on-close @close="closeDialogEvent" width="440px" top="20px">  
        <div class="change-email-form-content">
            <el-form ref="changeEmailForm" :rules="formRules" :model="form">
                <el-form-item prop="email">
                    <el-input v-model="form.email" placeholder="输入邮箱" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="captcha">
                    <div class="flex">
                        <div class="flex-1">
                            <el-input v-model="form.captcha" placeholder="输入验证码" autocomplete="off"></el-input>
                        </div>
                        <div class="margin-l-15">
                            <el-button plain @click="getCaptcha" :disabled="hasSend">{{hasSend?`${reSendTime}后可发送`:'发送验证码'}}</el-button>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <div class="actions text-center" style="margin-top: 50px;">
                <el-button round plain size="small" @click="show = false">取消</el-button>
                <el-button round type="primary" size="small" style="margin-left:20px;" :loading="loading" @click="submitChangeEmail">确认修改</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { userApi } from '@/utils/api';
import tools from '@/utils/tools';
export default {
    name: 'ChangeEmail',
    data(){
        const validateEmail = (rule, value, callback) => {
            if (value === ''){
                callback(new Error('请输入邮箱'));
            }else if (!tools.checkEmail(value)){
                callback(new Error('邮箱格式不正确'));
            }else{
                callback();
            }
        }
        return {
            reSendTime: 60,
            hasSend: false,
            sendTimer: null,
            show: false,
            loading: false,
            form: {
                email: '',
                captcha: '',
            },
            formRules: {
                email: [{ validator: validateEmail, trigger: 'blur' }],
                captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
            }
        }
    },
    computed: {
        ...mapState({
			userInfo: state => state.user.userData,
		}),
    },
    methods: {
        setShow(val){
            this.show = val;
        },
        closeDialogEvent(){
            this.form = {
                email: '',
                captcha: '',
            };
            clearInterval(this.sendTimer);
            this.sendTimer = null;
            this.reSendTime = 60;
            this.hasSend = false;
        },
        getCaptcha(){
            let self = this;
            if(!this.form.email){
                this.$message.error('请填写邮箱');
                return;
            }
            if(!tools.checkEmail(this.form.email)){
                this.$message.error('邮箱格式不正确');
                return;
            }
            this.$ajax.post(userApi.sendCaptchaAuth, {
                account: this.form.email, 
                authType: 'ACCOUNT'
            }).then(res=> {
                if(res.status === 200){
                    this.$message.success('验证码已发送，请注意查收');
                    this.hasSend = true;
                    this.reSendTime = this.reSendTime - 1;
                    this.sendTimer = setInterval(()=> {
                        self.reSendTime = self.reSendTime - 1;
                        if(self.reSendTime < 0){
                            clearInterval(self.sendTimer);
                            self.reSendTime = 60;
                            self.hasSend = false;
                        }
                    }, 1000);
                }
            })
        },
        submitChangeEmail(){
             this.$refs['changeEmailForm'].validate((valid) => {
                if (valid) {
                    //成功
                    // console.log('success');
                    let url = this.$ajax.format(userApi.settingAccount,{id: this.userInfo.userId});
                    let postData={
                        account: this.form.email,
                        captcha: this.form.captcha,
                        authType: 'ACCOUNT',
                    };
                    this.loading = true;
                    this.$ajax.post(url,postData).then(res=>{
                        if(res.status === 200){
                            this.$message.success('绑定/更改邮箱成功');
                            this.show = false;
                            this.$store.dispatch('user/queryUserInfoAction');
                        }
                    }).finally(()=>{
                        this.loading = false;
                    });
                    
                } else {
                    return false;
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.change-email-form-content{
    padding: 0 60px;
}
</style>
